<template>
	<div id="trafficCharge">
		<div class="chargeOne">
			<div class="lineLeft">姓名</div>
			<div class="basicRight">
				<input placeholder="请输入您的姓名" class="trainlineInput" v-model="formValue.name" maxlength="20" />
			</div>
		</div>
    <div class="chargeOne">
			<div class="lineLeft">电话</div>
			<div class="basicRight">
				<input placeholder="请输入您的手机号" maxlength="11" pattern="[0-9]*" v-model="formValue.phone" @blur="phoneBulr" type="tel" class="trainlineInput" />
			</div>
		</div>
		<div class="chargeOne1">
			<div class="lineLeft">车务需求</div>
			<div class="basicRight" @click="showTrainDemand" >
        <div class="statusText1" v-if='formValue.trainDemandValue'>{{formValue.trainDemandValue}}</div>
				<div class="statusText" v-else>请选择</div>
				<div class="to_right"></div>
			</div>
		</div>
		<!-- 车务需求 -->
		<van-overlay :show="isTrainDemand" :lock-scroll='false'>
			<div class="wrapper">
				<div class="block">
					<div class="closeTop">
            <img class="closeImg" @click="closeTrainDemand" src="../../assets/img/system-closeb@2x.png"/>
          </div>
          <div style="height: 10rem;overflow: scroll;">
            <div class="driverLine" v-for='(v, i) in trainDemandList' :key='i' @click="selectTrainDemand(v.demandName,v.id,i)" >
              <div class="driverText" ref="liTrainDemand">{{v.demandName}}</div>
              <img src="../../assets/img/red_select.png" ref="imgTrainDemand" style="display: none;" class="selectImg"/>
            </div>
          </div>
          <div class="trainDemandFooter">
            <div class="footerLeft" @click='resetTrainDemand'>重置</div>
            <div class="footerRight" @click="confirmTrainDemand">确定</div>
          </div>
				</div>
			</div>
		</van-overlay>
		<div class="chargeOne">
			<div class="lineLeft">接送车服务</div>
			<div class="basicRight" @click="showShuttleService">
        <div class="statusText1" v-if='formValue.shuttleServiceValue'>{{formValue.shuttleServiceValue}}</div>
				<div class="statusText" v-else>请选择</div>
				<div class="to_right"></div>
			</div>
		</div>
		<!-- 接送车服务 -->
		<van-overlay :show="isShuttleService" @click="closeShuttleService">
			<div class="wrapper">
				<div class="block">
					<div class="closeTop">
            <img class="closeImg" @click="closeShuttleService" src="../../assets/img/system-closeb@2x.png"/>
          </div>
          <div class="driverLine" v-for='(v, i) in shuttleServiceList' :key='i' @click="selectShuttleService(v,i)">
            <div class="driverText" :style="{'color' : (formValue.shuttleServiceType === i ? '#E50012' : '#333333')}">{{v}}</div>
            <img src="../../assets/img/red_select.png" v-if='formValue.shuttleServiceType === i' class="selectImg"/>
          </div>
				</div>
			</div>
		</van-overlay>
    <div class="chargeOne" @click="showTime" v-if='formValue.shuttleServiceType == 1'>
			<div class="lineLeft">时间</div>
			<div class="basicRight">
				<div class="statusText1" v-if='formValue.time'>{{formValue.time}}</div>
				<div class="statusText" v-else>请选择</div>
				<div class="to_right"></div>
			</div>
		</div>
		<!-- 时间 -->
		<van-overlay :show="isTime">
			<van-datetime-picker
				v-model="currentDate"
				type="date"
				title="时间"
				:min-date="minDate"
				:max-date="maxDate"
				@confirm='selectTime'
				@cancel='closeTime'
			/>
		</van-overlay>
    <div class="chargeOne" v-if='formValue.shuttleServiceType == 1'>
			<div class="lineLeft">地址</div>
			<div class="basicRight">
				<input placeholder="请填写地址" class="trainlineInput" @blur="addressBlur" v-model="formValue.address" maxlength="50" />
			</div>
		</div>
    <div class="chargeOne">
			<div class="lineLeft">备注</div>
			<div class="basicRight">
				<input placeholder="请填写备注" v-model="formValue.remarks" class="trainlineInput" @blur="remarksBlur" maxlength="100" />
			</div>
		</div>
    <div class="serveTip">
      <div class="serveTipText">服务提示</div>
      <div class="serveTipText">1、代理车务过程中，因车辆或车务手续问题不能过检的，如已提供车务服务代理服务费不退还客户。</div>
      <div class="serveTipText">2、接送车过程中发生交通事故驾驶人员按交警处理结果为准负相应责任。</div>
      <div class="serveTipText">3、因不可抗拒因素终止代理服务的按上述情况退还服务费。 </div>
      <div class="serveTipText">4、委托方需开发票的，务必委托车务登记信息与付款信息一致，否则不予开票。</div>
      <div class="serveTipText">5、开票税费委托方承担。</div>
    </div>
    <div class="footerBox">
			<div class="footerBtn" @click='submitTraffic'>提交</div>
		</div>
	</div>
</template>

<script>
import { getUrlParam } from '../../lib/utils';
import { Dialog, Toast } from 'vant';
import { trafficTrainDemandList, trafficChargeSubmit } from '@/server/request'
let u = navigator.userAgent;
let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export default {
	data() {
		return {
			formValue:{
        trainDemandType:[]
      },
      isTrainDemand: false,
      isShuttleService: false,
      trainDemandList: [],
      shuttleServiceList:{
        0: '否',
        1: '是'
      },
      isTime: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(2100, 10, 1),
      currentDate: new Date(),
      trainDemandType: [],
      trainDemandValue: []
		}
	},
	created() {
		document.title = '委托车务'
		this.gettrainDemandList()
	},
	mounted() {
		
	},
	computed: {
		
	},
	methods: {
    gettrainDemandList(){
      let params = ''
		trafficTrainDemandList(params).then(res=>{
			if(res.status=='200'){
        this.trainDemandList = res.data
			}else{
				Toast.fail({
					message: res.msg,
					duration:'3000'
				});
			}
		})
    },
		showTrainDemand(){
			this.isTrainDemand = true
		},
		selectTrainDemand(Value,type,index){
      if(this.trainDemandType.indexOf(type) == -1){
        this.trainDemandValue.push(Value)
        this.trainDemandType.push(type)
        this.$refs.liTrainDemand[index].style.color = '#E50012'
        this.$refs.imgTrainDemand[index].style.display = 'block'
      }else{
        for(var i = 0 ; i < this.trainDemandValue.length ; i ++){
          if(this.trainDemandValue[i] == Value){
            this.$refs.liTrainDemand[index].style.color = '#333333'
            this.trainDemandValue.splice(i, 1)
            this.trainDemandType.splice(i, 1)
            this.$refs.imgTrainDemand[index].style.display = 'none'
          }
        }
      }
		},
		closeTrainDemand(){
			this.isTrainDemand = false
    },
    confirmTrainDemand(){
      this.isTrainDemand = false
      this.$set(this.formValue,'trainDemandValue',this.trainDemandValue.join('、'))
      this.$set(this.formValue,'trainDemandType',this.trainDemandType)
    },
    resetTrainDemand(){
      for(var i = 0 ; i < this.$refs.liTrainDemand.length ; i ++){
        this.$refs.liTrainDemand[i].style.color = '#333333'
        this.$refs.imgTrainDemand[i].style.display = 'none'
      }
			this.trainDemandType = []
			this.trainDemandValue = []
			this.formValue.trainDemandType = []
			this.formValue.trainDemandValue = []
    },
		showShuttleService(){
			this.isShuttleService = true
		},
		selectShuttleService(value,type){
			this.isShuttleService = false
      this.formValue.shuttleServiceType = type
      this.formValue.shuttleServiceValue = value
		},
		closeShuttleService(){
			this.isShuttleService = false
    },
    showTime(){
      this.isTime = true
    },
    selectTime(value){
      let date = new Date()
      let date1 = new Date(value)
      let ageDifferenceYear = date.getFullYear()-date1.getFullYear()
      let ageDifferenceMonth = (date.getMonth()+1)-(date1.getMonth()+1)
      let ageDifferenceDate = date.getDate()-date1.getDate()
      this.isTime = false
      this.formValue.time = this.formatDate(value)
      this.currentDate = new Date(value)
    },
    closeTime(){
      this.isTime = false
    },
    formatDate(value) {
      let date = new Date(value);
      let YY = date.getFullYear() + '-';
      let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      return YY + MM + DD ;
    },
    phoneBulr(){
			let reg1 = new RegExp(/^1[3456789]\d{9}$/)
			let regBoo1 = reg1.test(this.formValue.phone);
      if(!regBoo1){
				Dialog({message: '请输入正确手机号'})
        return false
			}
			return true
		},
		addressBlur(){
			setTimeout(() => {
				if (isiOS) {
					window.scrollTo(0, 0)
				}
			}, 300);
		},
		remarksBlur(){
			setTimeout(() => {
				if (isiOS) {
					window.scrollTo(0, 0)
				}
			}, 300);
		},
		submitTraffic(){
			if(!this.formValue.name){
				Toast('请填写姓名')
			}else if(!this.formValue.phone){
				Toast('请填写电话')
			}else if(this.formValue.trainDemandType.length <= 0){
				Toast('请选择车务需求')
			}else if(!this.formValue.shuttleServiceType){
				Toast('请选择接送车服务')
			}else if(this.formValue.shuttleServiceType == 1 && !this.formValue.time){
				Toast('请选择时间')
			}else if(this.formValue.shuttleServiceType == 1 && !this.formValue.address){
				Toast('请填写地点')
			}else if(this.phoneBulr()){
        Dialog.confirm({
          title: '提示',
          message: '提交后无法修改，请确认是否提交',
        }).then(() => {
            // on confirm
            let params = this.formValue
            trafficChargeSubmit({data: params}).then(res=>{
            	if(res.status=='200'){
							 this.$router.go(-1)
							 Toast('提交成功')
            	}else{
            		Toast.fail({
            			message: res.msg,
            			duration:'3000'
            		});
            	}
            })
          
        }).catch(() => {
          // on cancel
        });
				
			}
		}
	},
};
</script>

<style>
#trafficCharge{
  background-color: #fff;
}
.chargeOne{
	line-height: 0.98rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: 0.4rem;
	padding-right: 0.4rem;
	border-bottom: 1px solid #EEEEEE;
}
.chargeOne1{
  display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-left: 0.4rem;
	padding-right: 0.4rem;
	border-bottom: 1px solid #EEEEEE;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.lineLeft{
	color: #333333;
	font-size: 0.3rem;
}
.statusText{
	color: #CCCCCC;
	font-size: 0.3rem;
	margin-right: 0.1rem;
}
.statusText1{
	color: #666666;
	font-size: 0.3rem;
	margin-right: 0.1rem;
}
.wrapper {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	height: 100%;
}
.block {
	width: 100%;
	background-color: #fff;
  background-color: #fff;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
  position: relative;
}
.basicRight{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	width: 4.5rem;
}
.to_right {
	position: relative;
	margin-left: 0.1rem;
}
.to_right:after,
.to_right:before {
	border: solid transparent;
	content: ' ';
	height: 0;
	left: 100%;
	position: absolute;
	width: 0;
}
.to_right:after {
	border-width: 5px;
	border-left-color: #ffffff; 
	top: -5px;
}
.to_right:before {
	border-width: 7px;
	border-left-color: #999999;
	top: -7px;
}
.closeTop{
  margin-top: 0.24rem;
  margin-left: 0.4rem;
  margin-bottom: 0.24rem;
}
.closeImg{
  width: 0.4rem;
  height: 0.4rem;
}
.driverText{
	font-size: 0.3rem;
	color: #333333;
}
.driverLine{
  line-height: 1rem;
	margin: 0 0.24rem;
	padding: 0 0.16rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
  border-bottom: 1px solid #EEEEEE;
}
.trainlineInput{
	text-align: right;
	width: 5rem;
	line-height: 0.36rem;
}
.selectImg{
	width: 0.4rem;
	height: 0.4rem;
}
.van-area{
	position: fixed;
	bottom: 0;
	width: 100%;
}
.trainDemandFooter{
  height: 0.96rem;
  display: flex;
  flex-direction: row;
}
.footerLeft{
  width: 3.74rem;
  text-align: center;
  line-height: 0.96rem;
  font-size: 0.36rem;
  color: #333333;
}
.footerRight{
  width: 3.74rem;
  text-align: center;
  line-height: 0.96rem;
  font-size: 0.36rem;
  color: #ffffff;
  background-color: #E50012;
}
.van-picker{
	position: fixed;
	bottom: 0;
	width: 100%;
}
.serveTip{
  margin: 0.3rem 0.4rem 0;
}
.serveTipText{
  font-size: 0.26rem;
  color: #818181;
}
.footerBox{
  background-color: #ffffff;
  padding: 0.2rem 0;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
	position: fixed;
	bottom: 0;
}
.footerBtn {
  width: 6.7rem;
  height: 0.9rem;
  border-radius: 0.9rem;
  text-align: center;
  line-height: 0.9rem;
  background-color: #e50012;
  color: #ffffff;
}

</style>
